"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const PlayerTable = ({ gameData }) => {
    const maxValue = 10;
    return ((0, jsx_runtime_1.jsxs)("div", { className: "w-full max-w-sm", children: [(0, jsx_runtime_1.jsx)(PlayerStat, { gameData: gameData, maxValue: maxValue }), (0, jsx_runtime_1.jsx)(PlayerList, { gameData: gameData, maxValue: maxValue })] }));
};
const PlayerStat = ({ gameData, maxValue, }) => {
    return ((0, jsx_runtime_1.jsxs)("div", { className: "stat text-center", children: [(0, jsx_runtime_1.jsx)("div", { className: "stat-title", children: "Player" }), (0, jsx_runtime_1.jsx)("div", { className: "stat-value", children: `${gameData.length} / ${maxValue}` })] }));
};
const PlayerList = ({ gameData, maxValue, }) => {
    return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: (0, jsx_runtime_1.jsx)("div", { className: "overflow-x-auto rounded-3xl border-2 border-zinc-100 shadow-md", children: (0, jsx_runtime_1.jsxs)("table", { className: "table", children: [(0, jsx_runtime_1.jsx)("thead", { children: (0, jsx_runtime_1.jsxs)("tr", { children: [(0, jsx_runtime_1.jsx)("th", { className: "" }), (0, jsx_runtime_1.jsx)("th", { className: "w-full", children: "Name" })] }) }), (0, jsx_runtime_1.jsx)("tbody", { children: [...Array(maxValue)].map((_, index) => {
                            if (gameData.length > index) {
                                return ((0, jsx_runtime_1.jsxs)("tr", { children: [(0, jsx_runtime_1.jsx)("th", { children: index + 1 }), (0, jsx_runtime_1.jsx)("td", { children: gameData[index].userName })] }, "record_" + index));
                            }
                            else {
                                return ((0, jsx_runtime_1.jsxs)("tr", { children: [(0, jsx_runtime_1.jsx)("th", { children: index + 1 }), (0, jsx_runtime_1.jsx)("td", {})] }, "record_" + index));
                            }
                        }) })] }) }) }));
};
exports.default = PlayerTable;
