"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const IdCard = ({ isHost, roomId }) => {
    const [isClicked, setIsClicked] = (0, react_1.useState)(false);
    // コピーボタンをクリックしたときの処理
    const handleCopyClick = () => {
        navigator.clipboard.writeText(roomId);
        setIsClicked(true);
    };
    if (isHost) {
        return ((0, jsx_runtime_1.jsx)("div", { className: "mb-4 flex justify-center", children: (0, jsx_runtime_1.jsx)("div", { className: "card mt-2 flex w-full max-w-sm items-center rounded-2xl bg-primary/30 shadow-md lg:max-w-md", children: (0, jsx_runtime_1.jsxs)("div", { className: "card-body p-4", children: [(0, jsx_runtime_1.jsxs)("div", { className: "flex items-end lg:text-xl", children: ["\u30EB\u30FC\u30E0ID\uFF1A", (0, jsx_runtime_1.jsx)("span", { className: "mx-2 text-3xl font-bold lg:text-5xl", children: roomId }), (0, jsx_runtime_1.jsx)("div", { className: isClicked
                                        ? "tooltip tooltip-right tooltip-open tooltip-secondary"
                                        : "tooltip tooltip-right tooltip-secondary", "data-tip": isClicked ? "コピーしました" : "コピーする", children: (0, jsx_runtime_1.jsx)("button", { className: "text-primary hover:text-primary/50", onClick: () => handleCopyClick(), children: (0, jsx_runtime_1.jsx)("svg", { className: "size-6", fill: "none", stroke: "currentColor", strokeWidth: 1.5, viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg", children: (0, jsx_runtime_1.jsx)("path", { d: "M8.25 7.5V6.108c0-1.135.845-2.098 1.976-2.192.373-.03.748-.057 1.123-.08M15.75 18H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08M15.75 18.75v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5A3.375 3.375 0 0 0 6.375 7.5H5.25m11.9-3.664A2.251 2.251 0 0 0 15 2.25h-1.5a2.251 2.251 0 0 0-2.15 1.586m5.8 0c.065.21.1.433.1.664v.75h-6V4.5c0-.231.035-.454.1-.664M6.75 7.5H4.875c-.621 0-1.125.504-1.125 1.125v12c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V16.5a9 9 0 0 0-9-9Z", strokeLinecap: "round", strokeLinejoin: "round" }) }) }) })] }), (0, jsx_runtime_1.jsx)("p", { className: "lg:text-md text-center text-sm", children: "\u53C2\u52A0\u8005\u306B\u4F1D\u3048\u3088\u3046\uFF01" })] }) }) }));
    }
    else {
        return null;
    }
};
exports.default = IdCard;
