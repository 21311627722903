"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const useAudio_1 = require("@/hooks/useAudio");
const AudioSwap = () => {
    // カスタムフックuseAudioから必要なデータを取得
    const audio = (0, useAudio_1.useAudio)("./cocktail_recipe.mp3");
    const audioSwapText = audio.playing ? "音楽を停止" : "音楽を再生";
    return ((0, jsx_runtime_1.jsx)("div", { className: "tooltip tooltip-bottom tooltip-primary flex", "data-tip": audioSwapText, children: (0, jsx_runtime_1.jsxs)("label", { "aria-label": "\u97F3\u697D\u518D\u751F\u306E\u5207\u308A\u66FF\u3048", className: "swap", children: [(0, jsx_runtime_1.jsx)("input", { type: "checkbox", onClick: audio.toggle }), (0, jsx_runtime_1.jsx)(StopIcon, {}), (0, jsx_runtime_1.jsx)(PlayIcon, {})] }) }));
};
const StopIcon = () => {
    return ((0, jsx_runtime_1.jsx)("svg", { className: "swap-on fill-current text-white", height: "32", viewBox: "0 0 24 24", width: "32", xmlns: "http://www.w3.org/2000/svg", children: (0, jsx_runtime_1.jsx)("path", { d: "M14,3.23V5.29C16.89,6.15 19,8.83 19,12C19,15.17 16.89,17.84 14,18.7V20.77C18,19.86 21,16.28 21,12C21,7.72 18,4.14 14,3.23M16.5,12C16.5,10.23 15.5,8.71 14,7.97V16C15.5,15.29 16.5,13.76 16.5,12M3,9V15H7L12,20V4L7,9H3Z" }) }));
};
const PlayIcon = () => {
    return ((0, jsx_runtime_1.jsx)("svg", { className: "swap-off fill-current text-white", height: "32", viewBox: "0 0 24 24", width: "32", xmlns: "http://www.w3.org/2000/svg", children: (0, jsx_runtime_1.jsx)("path", { d: "M3,9H7L12,4V20L7,15H3V9M16.59,12L14,9.41L15.41,8L18,10.59L20.59,8L22,9.41L19.41,12L22,14.59L20.59,16L18,13.41L15.41,16L14,14.59L16.59,12Z" }) }));
};
exports.default = AudioSwap;
