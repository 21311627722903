"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const ErrorAlert = ({ errorMsg, setErrorMsg }) => {
    if (errorMsg === "") {
        return null;
    }
    else {
        return ((0, jsx_runtime_1.jsx)("div", { className: "fixed left-1/2 z-50 w-full max-w-max -translate-x-1/2 p-4", children: (0, jsx_runtime_1.jsxs)("div", { className: "alert alert-warning shadow-md", role: "alert", children: [(0, jsx_runtime_1.jsx)("svg", { className: "h-6 w-6 shrink-0 stroke-current", fill: "none", viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg", children: (0, jsx_runtime_1.jsx)("path", { d: "M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z", strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: "2" }) }), (0, jsx_runtime_1.jsx)("span", { children: errorMsg }), (0, jsx_runtime_1.jsx)("button", { className: "btn btn-ghost btn-sm", onClick: () => setErrorMsg(""), children: "\u2715" })] }) }));
    }
};
exports.default = ErrorAlert;
