"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// イベントの抽象クラス
class EventClass {
}
// RES_CREATEROOM
class RES_CREATEROOM {
    EventName = "RES_CREATEROOM";
    roomId = "";
    errorMsg = "";
    constructor(roomId = "", errorMsg = "") {
        this.roomId = roomId;
        this.errorMsg = errorMsg;
    }
    parseEventParameter(parameter) {
        const { roomId, errorMsg } = JSON.parse(parameter);
        return new RES_CREATEROOM(roomId, errorMsg);
    }
}
// NOTIFY_GAMEDATA
class NOTIFY_GAMEDATA {
    EventName = "NOTIFY_GAMEDATA";
    gameData = [];
    constructor(gameData = []) {
        this.gameData = gameData;
    }
    parseEventParameter(parameter) {
        const gameData = JSON.parse(parameter);
        return new NOTIFY_GAMEDATA(gameData);
    }
}
// RES_JOIN
class RES_JOIN {
    EventName = "RES_JOIN";
    errorMsg = "";
    constructor(errorMsg = "") {
        this.errorMsg = errorMsg;
    }
    parseEventParameter(parameter) {
        const errorMsg = parameter;
        return new RES_JOIN(errorMsg);
    }
}
// NOTIFY_THEME
class NOTIFY_THEME {
    EventName = "NOTIFY_THEME";
    theme = "";
    constructor(theme = "") {
        this.theme = theme;
    }
    parseEventParameter(parameter) {
        const theme = parameter;
        return new NOTIFY_THEME(theme);
    }
}
// NOTIFY_NUMBER
class NOTIFY_NUMBER {
    EventName = "NOTIFY_NUMBER";
    number = 0;
    constructor(number = 0) {
        this.number = number;
    }
    parseEventParameter(parameter) {
        const number = Number(parameter);
        return new NOTIFY_NUMBER(number);
    }
}
// RES_START
class RES_START {
    EventName = "RES_START";
    errorMsg = "";
    constructor(errorMsg = "") {
        this.errorMsg = errorMsg;
    }
    parseEventParameter(parameter) {
        const errorMsg = parameter;
        return new RES_START(errorMsg);
    }
}
// RES_RESULT
class RES_RESULT {
    EventName = "RES_RESULT";
    result = false;
    constructor(result = false) {
        this.result = result;
    }
    parseEventParameter(parameter) {
        let result = false;
        if (parameter === "TRUE") {
            result = true;
        }
        return new RES_RESULT(result);
    }
}
class RES_CLOSEROOM {
    EventName = "RES_CLOSEROOM";
    errorMsg = "";
    constructor(errorMsg = "") {
        this.errorMsg = errorMsg;
    }
    parseEventParameter(parameter) {
        const errorMsg = parameter;
        return new RES_CLOSEROOM(errorMsg);
    }
}
class RES_NEXTGAME {
    EventName = "RES_NEXTGAME";
    errorMsg = "";
    constructor(errorMsg = "") {
        this.errorMsg = errorMsg;
    }
    parseEventParameter(parameter) {
        const errorMsg = parameter;
        return new RES_NEXTGAME(errorMsg);
    }
}
// SocketEventクラス
class SocketEvent {
    // 送信イベント
    static REQ_CREATEROOM = "REQ_CREATEROOM";
    static REQ_JOIN = "REQ_JOIN";
    static REQ_RESULT = "REQ_RESULT";
    static REQ_START = "REQ_START";
    static UPDATE_ANSWER = "UPDATE_ANSWER";
    static UPDATE_GAMEDATA = "UPDATE_GAMEDATA";
    static REQ_CLOSEROOM = "REQ_CLOSEROOM";
    static REQ_NEXTGAME = "REQ_NEXTGAME";
    // 受信イベントのクラスインスタンス生成
    static RES_CREATEROOM = new RES_CREATEROOM();
    static NOTIFY_GAMEDATA = new NOTIFY_GAMEDATA();
    static RES_JOIN = new RES_JOIN();
    static NOTIFY_THEME = new NOTIFY_THEME();
    static NOTIFY_NUMBER = new NOTIFY_NUMBER();
    static RES_START = new RES_START();
    static RES_RESULT = new RES_RESULT();
    static RES_CLOSEROOM = new RES_CLOSEROOM();
    static RES_NEXTGAME = new RES_NEXTGAME();
}
exports.default = SocketEvent;
