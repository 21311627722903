"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.socket = void 0;
const socket_io_client_1 = require("socket.io-client");
const serverUrl = process.env.SERVER_URL;
if (!serverUrl) {
    throw new Error("SERVER_URL is not defined in the environment variables.");
}
exports.socket = (0, socket_io_client_1.io)(serverUrl, {
    autoConnect: false,
});
